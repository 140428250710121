<template>
  <ValidationProvider
    v-slot="{ errors }"
    tag="div"
    :name="field"
    :vid="vid"
    :rules="rules">
    <!--Field-->
    <div class="input__field"
         :class="{'errors__field': errors[0]}">
      <!--Prefix icon-->
      <slot name="prefix" />

      <!--Input-->
      <input :type="type"
             :disabled="disabled"
             :placeholder="placeholder"
             :value="value"
             :maxlength="maxLength"
             :class="{'custom-placeholder': customPlaceholder, 'has__prefix': prefix}"
             @focusout="handleFocusOut($event)"
             @input="handleType($event)"
             @focusin="handleFocusIn($event)"/>
    </div>

    <!--Message Error-->
    <span
      v-if="errors[0]"
      class="errors">
      {{ errors[0] }}
    </span>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'InputField',

  model: {
    prop: 'value',
    event: 'input'
  },

  props: {
    field: {
      type: String,
      default: ''
    },

    rules: {
      type: [String, Object],
      default: ''
    },

    vid: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      default: ''
    },

    value: {
      type: [String, Number],
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    prefix: {
      type: Boolean,
      default: false
    },

    maxLength: {
      type: Number,
      default: 524288
    },

    type: {
      type: String,
      default: 'text'
    },

    customPlaceholder: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    handleType ($event) {
      const text = $event.target.value

      if (text.length > this.maxLength) {
        return
      }

      this.$emit('input', text)
    },
    handleFocusOut ($event) {
      this.$emit('focusout', $event.target.value)
    },
    handleFocusIn ($event) {
      this.$emit('focusin', $event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
  .input__field {
    position: relative;
    input {
      width: 100%;
      border-radius: 6px;
      @include font-size(20px);
      padding: 16px 14px;
      height: 53px;
      @media #{$info-phone} {
        padding: 11px 14px;
        height: 42px;
        font-size: 16px;
      }
    }
    input::placeholder {
      color: #BDBFAE;
      @media #{$info-phone} {
        font-size: 16px;
      }
    }
    .custom-placeholder {
        &::placeholder {
          color: #FF0800;
          opacity: 0.5;
        }
    }
    input[disabled] {
      background: rgba(255,255,255,.35);
      color: #282828;
    }
    .prefix {
      color: red;
    }
    .has__prefix {
      padding-left: 45px;
    }
  }

  // custom input code forgot pass
  .code-token {
    .input__field {
      input {
        padding-left: 46px;
      }
    }
  }
</style>
